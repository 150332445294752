<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
        <div style="margin-top:15px;width:100%">
             <el-row v-for="(page, index) of pages" :key="index" :gutter="20" style="margin:10px">
                <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="item of page" :key="item.dis_id">
                    <el-card style="height:175px">
                        <div style="position:relative;">
                            <div class="label" :style="{background:(item.dr_type===0?'#FFA500':(item.dr_type===1?'#008000':(item.dr_type===2?'red':'#507BCA'))),color:'white'}">
                                <div v-if="item.dr_type==0">时长券</div>
                                <div v-if="item.dr_type==1">金额券</div>
                                <div v-if="item.dr_type==2">折扣券</div>
                                <div v-if="item.dr_type==3">全免券</div>
                            </div>
                        </div>
                        <el-row>
                        <el-col :span="24">
                            <div class="line" style="width:50%">
                            <span style="float:left;font-size:23px;">{{item.dr_name}}</span>
                            </div>
                        </el-col>
                        </el-row>
                        <el-row  style="margin-top:10px" >
                            <div class="line" style="width:100%">
                                <el-col :span="15">
                                    <div style="font-size:13px;float:left;text-align:left">
                                        <span>领取<span style="color:red">{{item.dr_time_limit===0?' '+item.dr_time_value+'小时内 ':' 当天内 '}}</span>有效，<span>{{item.dr_type===3&&item.dr_limit_num===1?'有效期内重复使用':'单次有效'}}</span>，</span><br/>
                                        <span style="color:#FFB500">{{item.dr_type===3?'不可叠加':(item.dr_repeat_add===0?' 不可叠加 ':' 可叠加 ')}}</span>
                                    </div>
                                    </el-col>
                                    <el-col :span="9">
                                    <div style="font-size:28px;text-align:left;float:left">
                                        <span v-if="item.dr_type===0">{{item.dr_value}}小时</span>
                                        <span v-if="item.dr_type===1">{{item.dr_value/100}} 元</span>
                                        <span v-if="item.dr_type===2">{{item.dr_value/100}} 折</span>
                                        <span v-if="item.dr_type===3">全免</span>
                                    </div>
                                </el-col>
                            </div>
                        </el-row>
                        <el-row style="margin-top:20px">
                            <el-col :span="24">
                              <div class="p">
                                 <div>
                                    <span style="font-size:13px">售价：</span>
                                    <span style="font-size:20px;color:red">{{item.dr_price}}元</span>
                                </div>
                                <div>
                                    <el-button-group>
                                        <el-button icon="el-icon-edit" @click="Edit(item)"></el-button>
                                        <el-button icon="el-icon-delete" @click="Del(item)"></el-button>
                                    </el-button-group>
                                </div>
                              </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
             </el-row>
        </div>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" :width='screenWidth<738?"70%":(screenWidth<1200?"50%":"35%")' :append-to-body='true'>
            <el-form :model="disForm" :rules="rules" ref="disForm" label-width="120px" size='medium'>
                <el-form-item label="优惠券名" prop="dr_name">
                    <el-input v-model="disForm.dr_name"></el-input>
                </el-form-item>
                <el-form-item label="优惠券类型">
                    <el-select v-model="disForm.dr_type" style="width:100%;">
                        <el-option label="时长减免" :value='0'></el-option>
                        <el-option label="金额减免" :value='1'></el-option>
                        <el-option label="折扣减免" :value='2'></el-option>
                        <el-option label="全免" :value='3'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="disForm.dr_type===0?'单张额度(小时)':(disForm.dr_type===1?'单张额度(元)':(disForm.dr_type===2?'单张额度(折)':'单张额度'))"  prop="dr_value">
                    <el-input-number v-model="disForm.dr_value" :disabled="disForm.dr_type===3?true:false" controls-position="right" :precision="disForm.dr_type===0?0:2" :min="0" style="width:100%"></el-input-number>
                </el-form-item>
                <el-form-item label="售价" prop="dr_price">
                    <el-input-number v-model="disForm.dr_price" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                </el-form-item>
                <el-form-item label="使用有效期">
                    <el-input v-model="disForm.dr_time_value" style="width:100%;" :disabled="disForm.dr_time_limit===1?true:false" class="input-with-select">
                        <el-select v-model="disForm.dr_time_limit" slot="prepend" style="width:100px;">
                            <el-option label="小时" :value='0'></el-option>
                            <el-option label="当天" :value='1'></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item v-if="disForm.dr_type===3" label="有效期内使用">
                    <div style="float:left">
                        <el-radio v-model="disForm.dr_limit_num" :label="0">单次有效</el-radio>
                        <el-radio v-model="disForm.dr_limit_num" :label="1">可重复使用</el-radio>
                    </div>
                </el-form-item>
                <el-form-item v-if="disForm.dr_type!==3" label="叠加使用">
                    <div style="float:left">
                        <el-radio v-model="disForm.dr_repeat_add" :label="0">不允许</el-radio>
                        <el-radio v-model="disForm.dr_repeat_add" :label="1">允许</el-radio>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      screenWidth: 0,
      DisRulesListData: [],
      dialogVisible: false,
      title: '',
      disForm: {
        dr_id: 0,
        dr_name: '',
        dr_type: 0,
        dr_value: 0,
        dr_price: 0.00,
        dr_time_limit: 0,
        dr_time_value: 24,
        dr_limit_num: 0,
        dr_repeat_add: 0
      },
      rules: {
        dr_name: [{ required: true, message: '请输入优惠券名', trigger: 'blur' }],
        dr_value: [{ required: true, message: '请输入优惠券额度', trigger: 'blur' }],
        dr_price: [{ required: true, message: '请输入优惠券价格', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.screenWidth = document.body.clientWidth
    this.GetDisRulesData()
  },
  computed: {
    pages () {
      const pages = []
      this.DisRulesListData.forEach((item, index) => {
        const page = Math.floor(index / 4)// 4条为一行
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  },
  methods: {
    GetDisRulesData () {
      util.Get('disrules/getdisrulesdatalist').then(res => {
        if (res.rpStatus === 10000) {
          this.DisRulesListData = res.list
        }
      })
    },
    AddClick () {
      if(util.CheckUserAuth('3-1-1')){
        this.dialogVisible = true
        this.title = '新增'
        this.disForm.dr_id = 0
        this.disForm.dr_name = ''
        this.disForm.dr_type = 0
        this.disForm.dr_value = 0
        this.disForm.dr_price = 0.00
        this.disForm.dr_time_limit = 0
        this.disForm.dr_time_value = 24
        this.disForm.dr_limit_num = 0
      }
    },
    Edit (row) {
      if(util.CheckUserAuth('3-1-2')){
        this.disForm = JSON.parse(JSON.stringify(row))
        if (row.dr_type === 1 || row.dr_type === 2) {
          this.disForm.dr_value = row.dr_value / 100
        }

        this.dialogVisible = true
        this.title = '修改'
      }
    },
    Del (row) {
      if(util.CheckUserAuth('3-1-3')){
        this.$confirm('确定删除优惠券“' + row.dr_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('disrules/del?id=' + row.dr_id+'&name='+row.dr_name).then(res => {
            if (res.rpStatus === 10000) { this.GetDisRulesData() } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
          })
        }).catch(e => e)
      }
    },
    submit () {
      this.$refs.disForm.validate((valid) => {
        if (valid) {
          if (this.disForm.dr_type === 1 || this.disForm.dr_type === 2) { this.disForm.dr_value = this.disForm.dr_value * 100 }
          util.Post('disrules/edit', this.disForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetDisRulesData()
              this.dialogVisible = false
            } else { this.$message.error(res.rpStatus) }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.p{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.label{
  font-size:15px;
  color: white;
  line-height:30px;
  position:absolute;
  right:0;
  top:0;
  z-Index:2;
  padding:0 2em;
  -webkit-transform-origin:left bottom;
  -moz-transform-origin:left bottom;
  transform-origin:left bottom;
  -webkit-transform:translate(50%,-160%) rotate(45deg);
  -moz-transform:translate(50%,-160%) rotate(45deg);
  transform:translate(50%,-160%) rotate(45deg);
  text-indent:0;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
